import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import H1 from "../components/h1"
import ReactHtmlParser from "react-html-parser"
import BtnClose from "../components/btnClose"
import ContactForm from "../components/contactForm"

const postImgDir = "http://festpodroze.pl/img/posts/"

const ContentPost = styled.div`
    margin: 1rem;
    padding: 2rem 2rem 2rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

const Header = styled.header`
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    margin: 0 0 1.5rem 0;
`

const ImgPost = styled.img`
    display: inline-block;
    width: 25%;
    height: 20%;
    padding: 0.5rem 2rem 0 0.5rem;
    margin: 0 auto;
    border-color: ${({ theme }) => theme.colors.LineColor};
    border-right: 1px solid ${({ theme }) => theme.colors.LineColor};
`

const BlockText = styled.div`
    flex-basis: 65%;
`
const Description = styled.div`
    color: ${({ theme }) => theme.colors.darkgrey};
    font-size: 95%;
`

const ContentHtml = styled.div`
    margin: 1.2rem 0 0 0;
    font-size: 90%;
`
const HtmlParser = html => {
    return ReactHtmlParser(html)
}

const NaZamowienie = () => {
    const dataPage = useStaticQuery(graphql`
        query {
            podroze {
                post(where: { id: "cjzwshaikb7ox0814qgpooj7e" }) {
                    id
                    data
                    departure
                    description
                    icon
                    slug
                    status
                    thumbnail
                    title
                    category
                    active
                    details {
                        html
                    }
                }
            }
        }
    `)
    return (
        <Layout>
            <SEO title="Home" />
            <ContentPost>
                <Header>
                    <H1 title={dataPage.podroze.post.title} page="card"></H1>
                    <BtnClose />
                </Header>
              
                <ImgPost
                    alt={`zdjecie-miejsca-podrozy ${dataPage.podroze.post.thumbnail} `}
                    src={postImgDir + dataPage.podroze.post.thumbnail}
                />

                <BlockText>
                    <Description>
                        {dataPage.podroze.post.description}
                    </Description>
                    <ContentHtml>
                        {HtmlParser(dataPage.podroze.post.details.html)}
                    </ContentHtml>
                    <ContactForm />
                </BlockText>
            </ContentPost>
        </Layout>
    )
}

export default NaZamowienie
