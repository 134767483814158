import React from 'react';
import styled from 'styled-components';
import {Link}  from 'gatsby'

const Inner = styled.div`
	width: inherit;
	display:flex;

	&::before, &::after {
		position: absolute;
		content: '';
		height: 1px;
		width: inherit;
		background: ${({ theme }) => theme.colors.dark};;
		left: 0;
		transition: all .3s ease-in;
	}

	&::before {
		top: 50%;
		transform: rotate(45deg);
	}

	&::after {
		bottom: 50%;
		transform: rotate(-45deg);
	}
`;

const CloseLink = styled(Link)`
	position: relative;
	height: 3rem;
	display:block;
	text-decoration:none;
	width: 2.3rem;
	border-radius: 2px;
	&:hover ${Inner}::before, &:hover ${Inner}::after {
		transform: rotate(0);
	}
	&:hover ${Inner}::before {
  		top: 5px;
	}
	&:hover ${Inner}::after {
		bottom:5px;
	}
`;


const MyText = styled.span`
	opacity: 0;
	color: ${({ theme }) => theme.colors.darkgrey};;
	font-size: 0.5rem;
	line-height: 1rem;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .3s ease-in;
	padding: 1rem 0;
	&:hover {
		opacity: 1;
	}
`;

const BtnClose = () => (
  <CloseLink to="/">
    <Inner>
      <MyText>główna</MyText>
    </Inner>
  </CloseLink>
);

export default BtnClose;
