import React from "react"
// import PropTypes from "prop-types"
import styled from "styled-components"

const Form = styled.form`
    background: #fff;
    border-radius: 2px;
    padding: 0 2rem 2rem 0;
    ${({ theme }) => theme.media.miniphone} {
        padding: 0;
    }
`
const Label = styled.label`
    font-size: 13px;
    color: #555555;
    line-height: 1.5;
    text-transform: uppercase;
    width: 100%;
    padding-bottom: 11px;
`

const Input = styled.input`
    display: block;
    position: relative;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    margin-bottom: 1rem;
    height: 2rem;
    padding: 0 0 0 1rem;
    ${({ theme }) => theme.media.miniphone} {
        width: 90%;
    }
    ${({ theme }) => theme.media.phone} {
        width: 90%;
    }
    ${({ theme }) => theme.media.tablet} {
        width: 60%;
    }
`

const Textarea = styled.textarea`
    display: block;
    min-height: 162px;
    padding-top: 19px;
    padding-bottom: 1.2rem;
    border: 1px solid #e6e6e6;
    padding: 0.3rem 0 0 1rem;
    ${({ theme }) => theme.media.miniphone} {
        width: 90%;
    }
    ${({ theme }) => theme.media.phone} {
        width: 90%;
    }
    ${({ theme }) => theme.media.tablet} {
        width: 60%;
    }
`
const Submit = styled.input`
    margin: 1rem auto;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.important};
    width: 50%;
    padding: 0.5rem;
    border-color: #d867c6;
    border: 1px solid ${({ theme }) => theme.colors.important};
    text-transform: uppercase;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #eee;
    text-shadow: none;
    ${({ theme }) => theme.media.miniphone} {
        width: 90%;
    }
    ${({ theme }) => theme.media.phone} {
        width: 90%;
    }
    ${({ theme }) => theme.media.tablet} {
        width: 60%;
    }

    :hover {
        background: ${({ theme }) => theme.colors.important};
        color: ${({ theme }) => theme.colors.white};
    }
`

class ContactForm extends React.Component {
    render() {
        return (
            <Form
                action="https://docs.google.com/forms/d/e/1FAIpQLSe8YxBD_Ucf1STe2wBhqulOHweSKP36R4-aX7zmUW65t2wGDQ/formResponse"
                method="POST"
                target="_self"
            >
                <Label>
                    Imię:
                    <Input
                        type="text"
                        name="entry.1188048303"
                        placeholder="Imię"
                        require
                    />
                </Label>
                <Label>
                    Nazwisko:
                    <Input
                        type="text"
                        name="entry.1164634995"
                        placeholder="Nazwisko"
                    />
                </Label>
                <Label>
                    Adres e-mail:
                    <Input
                        type="text"
                        name="entry.2086733589"
                        placeholder="Adres poczty email"
                        require
                    />
                </Label>
                <Label>
                    Treść wiadomości:
                    <Textarea
                        type="textarea"
                        rows="10"
                        cols="40"
                        name="entry.1864835736"
                        placeholder="Napisz wiadomość do nas..."
                        require
                    />
                </Label>
                <Submit type="submit" value="Wyślij pytanie" />
            </Form>
        )
    }
}

export default ContactForm
