import React from "react"
import styled from "styled-components"

const Hader1 = styled.h1`
    font-family: "loveloblack", sans-serif;

    color: ${props => {
        if (props.page === "mainPage") {
            return ({ theme }) => theme.colors.blue
        } else {
            return ({ theme }) => theme.colors.important
        }
    }};

    
    font-size: 1.8rem;
    ${({ theme }) => theme.media.miniphone} {
        margin: 3rem 0 0 0;
        text-align: center;
        font-size: 1.2rem;
    }
    ${({ theme }) => theme.media.phone} {
        margin: 3rem 0 0 0;
        text-align: center;
        font-size: 1.2rem;
    }
    ${({ theme }) => theme.media.tablet} {
        font-size: 1.5rem;
    }
    ${({ theme }) => theme.media.desktop} {
        font-size: 2rem;
        text-align: left;
        margin: ${props => {
        if (props.page === "card") {
            return "3rem 0 0 4rem"
        } 
        if (props.page === "page"){
            return "3rem 0 0 10rem"
        } else {
            return "3rem 0 0 0rem"
        }
    }};
        width: ${props => {
            if (props.page === "card") {
                return "100%"
            } else {
                return "84%"
            }
        }};
    }
`

const H1 = props => <Hader1 page={props.page}>{props.title}</Hader1>

export default H1
